import { css } from "@emotion/css";
import clsx from "clsx";
import React from "react";
import ComposioLogo from "~/ui/components/base/logo";
import { NonAuthScreenBackdrop } from "./components/NonAuthScreenBackdrop";

export function BlankMessageState({
    children,
    marginTop,
    width = 420,
    height = 440,
}: {
    children: React.ReactNode;
    marginTop?: string;
    width?: number;
    height?: number;
}) {
    return (
        <NonAuthScreenBackdrop>
            <div
                className={clsx(
                    `] absolute 
                    relative left-[50%]  top-[50%] z-20
                   flex translate-x-[-50%] translate-y-[-55%]  flex-col overflow-hidden rounded-[24px] border-[1px] border-[#DBDBDB] bg-[#F7F7F7] `,
                    css`
                        height: ${height}px;
                        width: ${width}px;
                    `,
                )}
            >
                <div className="flex h-full w-full">
                    {/* Right Section */}
                    <div className=" flex w-full min-w-[full] flex-col justify-between bg-[#fff] px-[44px] pt-[36px] pt-[66xx] text-[14px] font-[400] text-[#606060]">
                        <div className="flex w-full flex-col items-center">
                            <ComposioLogo textColor={"#171717"} height={28} />
                            <div className="mt-3 text-[14px] font-medium tracking-[0.35px] text-black-100">
                                Take your AI agent live to prod
                            </div>
                            <div
                                style={{
                                    marginTop: marginTop,
                                }}
                                className="mt-10 flex w-full flex-col items-center"
                            >
                                {children}
                            </div>
                            {/* <If condition={showVerificationScreen}>
                            <div className="mt-10 flex w-full flex-col items-center">
                                <div className="font-avenirn text-[18px] font-[700] text-black-200">Verify your email</div>
                                <div className="mt-3 text-center leading-[170%] text-black-600">
                                    We have sent a magic link to your email address. Please open it to continue.
                                </div>

                                <div
                                    onClick={() => setShowVerificationScreen(false)}
                                    className="text-black-6000 mt-6 flex cursor-pointer items-center gap-1 pr-2 text-[13px] font-[500] underline"
                                >
                                    <ChevronLeftIcon height={14} width={14} strokeWidth={2.2} />
                                    Go back
                                </div>
                            </div>
                        </If> */}
                        </div>
                    </div>
                </div>
            </div>
        </NonAuthScreenBackdrop>
    );
}
