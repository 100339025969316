import * as Sentry from "@sentry/nextjs";
import { MoveLeftIcon } from "lucide-react";
import Error from "next/error";
import { Button } from "~/design-system/atom/button";
import { BlankMessageState } from "~/ui/screens/login_screen/blankMessageState";

export const CustomErrorComponent = ({ description = " We've logged the error and will be working on it.", ...props }) => {
    return (
        <BlankMessageState width={500} height={360}>
            <div className="mt-1 font-avenirn text-[20px] font-[700] text-black-100">Error: {props.statusCode} 🛠️👾</div>
            <div className="mt-3 text-[14px] text-black-200">{description}</div>
            <div>
                <Button
                    variant="default"
                    className="mt-8 px-3"
                    onClick={() => {
                        window.location.href = "/apps?category=all";
                    }}
                    size={36}
                >
                    <MoveLeftIcon height={14} width={14} className="mr-2 " /> Go back to app
                </Button>
            </div>
        </BlankMessageState>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
CustomErrorComponent.getInitialProps = async (contextData: any) => {
    // In case this is running in a serverless function, await this in order to give Sentry
    // time to send the error before the lambda exits
    await Sentry.captureUnderscoreErrorException(contextData);
    // This will contain the status code of the response
    return Error.getInitialProps(contextData);
};

export default CustomErrorComponent;
